$(document).ready(function () {
  $("#showTakeOver").on("click", function () {
    var me = $(this);
    var navbar = $(".navbar-wrapper");
    var menu = $("#takeOver");

    if (menu.hasClass("hidden")) {
      $("html").addClass("freeze");
      $("body").addClass("freeze");
      navbar.addClass("take-over-open");
      menu.removeClass("hidden");
      me.text("Close");
    } else {
      $("html").removeClass("freeze");
      $("body").removeClass("freeze");
      navbar.removeClass("take-over-open");
      menu.addClass("hidden");
      me.text("Menu");
    }
  });

  $(".showSecondary").on("click", function () {
    var secondary = $(".navlist-secondary");

    secondary.addClass("hidden");
    $(this).next(secondary).removeClass("hidden");
  });
});
